import React, { useState } from 'react';
import './Calculator.css';

const Calculator = () => {
  const [display, setDisplay] = useState('0');
  const [currentNumber, setCurrentNumber] = useState('0');
  const [previousNumber, setPreviousNumber] = useState('0');
  const [calculation, setCalculation] = useState('');

  const handleNumber = (num) => {
    if (currentNumber.length < 7) {
      if (currentNumber === '0') {
        setCurrentNumber(num);
        setDisplay(num);
      } else {
        setCurrentNumber(prev => prev + num);
        setDisplay(prev => prev + num);
      }
    }
  };

  const handleDot = () => {
    if (!currentNumber.includes('.')) {
      setCurrentNumber(prev => prev + '.');
      setDisplay(prev => prev + '.');
    }
  };

  const handleClear = () => {
    setDisplay('0');
    if (currentNumber !== '0') {
      setCurrentNumber('0');
    } else if (currentNumber === '0') {
      setPreviousNumber('0');
      setCurrentNumber('0');
    }
    setCalculation('');
  };

  const handleOperator = (op) => {
    setPreviousNumber(currentNumber);
    setCurrentNumber('0');
    setCalculation(op);
  };

  const handlePercent = () => {
    const result = parseFloat(currentNumber) / 100;
    setCurrentNumber(String(result));
    setDisplay(String(result));
  };

  const handleNegate = () => {
    if (currentNumber !== '0') {
      const negated = currentNumber.includes('-') 
        ? currentNumber.replace('-', '')
        : '-' + currentNumber;
      setCurrentNumber(negated);
      setDisplay(negated);
    }
  };

  const handleEquals = () => {
    const prev = parseFloat(previousNumber);
    const curr = parseFloat(currentNumber);
    let result;

    switch(calculation) {
      case '+': result = prev + curr; break;
      case '-': result = prev - curr; break;
      case '×': result = prev * curr; break;
      case '÷': result = curr === 0 ? 'Not a Number' : prev / curr; break;
      default: return;
    }

    const displayResult = typeof result === 'number' 
      ? (result % 1 === 0 ? String(result) : result.toFixed(2))
      : result;

    setCurrentNumber(String(result));
    setDisplay(displayResult);
    setPreviousNumber(String(prev));
  };

  return (
    <div className="calculator">
      <div className="calculator-display">{display}</div>
      <div className="calculator-keypad">
        <div className="function-keys">
          <button onClick={handleClear}>AC</button>
          <button onClick={handleNegate}>+/-</button>
          <button onClick={handlePercent}>%</button>
          <button className="operator" onClick={() => handleOperator('÷')}>÷</button>
        </div>
        <div className="digit-keys">
          <button onClick={() => handleNumber('7')}>7</button>
          <button onClick={() => handleNumber('8')}>8</button>
          <button onClick={() => handleNumber('9')}>9</button>
          <button className="operator" onClick={() => handleOperator('×')}>×</button>
          
          <button onClick={() => handleNumber('4')}>4</button>
          <button onClick={() => handleNumber('5')}>5</button>
          <button onClick={() => handleNumber('6')}>6</button>
          <button className="operator" onClick={() => handleOperator('-')}>−</button>
          
          <button onClick={() => handleNumber('1')}>1</button>
          <button onClick={() => handleNumber('2')}>2</button>
          <button onClick={() => handleNumber('3')}>3</button>
          <button className="operator" onClick={() => handleOperator('+')}>+</button>
          
          <button className="zero" onClick={() => handleNumber('0')}>0</button>
          <button onClick={handleDot}>.</button>
          <button className="operator" onClick={handleEquals}>=</button>
        </div>
      </div>
    </div>
  );
};

export default Calculator; 