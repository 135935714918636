import React from 'react';
import { FaComment, FaBan, FaUnlock } from 'react-icons/fa';

const UsersList = ({ 
  users, 
  currentUser, 
  blockedUsers,
  onStartDM,
  onToggleBlock,
  selectedUser,
  connectedAs
}) => {
  return (
    <div className="users-sidebar">
      <div className="users-header">
        <div className="connected-user">Connected as: {connectedAs}</div>
        <div>Online Users ({users.length})</div>
      </div>
      <div className="users-list">
        {users.map(user => (
          <div 
            key={user.id} 
            className={`user-item ${user.online ? 'online' : ''} ${
              blockedUsers.includes(user.id) ? 'blocked' : ''
            } ${selectedUser?.id === user.id ? 'selected' : ''}`}
          >
            <span className="user-name">{user.username}</span>
            <div className="user-actions">
              <button
                className="action-button"
                onClick={() => onStartDM(user)}
                title="Direct Message"
              >
                <FaComment />
              </button>
              <button
                className="action-button"
                onClick={() => onToggleBlock(user.id)}
                title={blockedUsers.includes(user.id) ? "Unblock" : "Block"}
              >
                {blockedUsers.includes(user.id) ? <FaUnlock /> : <FaBan />}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UsersList; 