import React, { createContext, useContext, useState, useEffect } from 'react';

const WalletContext = createContext(null);

export const useWallet = () => useContext(WalletContext);

export const WalletConnectionProvider = ({ children }) => {
  const [wallet, setWallet] = useState(null);
  const [walletType, setWalletType] = useState(null);
  const [connected, setConnected] = useState(false);
  const [publicKey, setPublicKey] = useState(null);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    const checkWallets = async () => {
      try {
        // Check for Phantom
        if ("solana" in window && window.solana.isPhantom) {
          setWallet(window.solana);
          setWalletType('phantom');
          if (window.solana.isConnected) {
            setConnected(true);
            setPublicKey(window.solana.publicKey.toString());
          }
        }
        // Check for Solflare
        else if ("solflare" in window) {
          const solflare = window.solflare;
          setWallet(solflare);
          setWalletType('solflare');
          // Check if already connected
          try {
            const resp = await solflare.connect({ onlyIfTrusted: true });
            setConnected(true);
            setPublicKey(resp.publicKey.toString());
          } catch (e) {
            // Not already connected
          }
        }
      } catch (error) {
        console.error('Wallet check error:', error);
      }
    };

    checkWallets();

    // Add event listeners for connection changes
    const handleConnect = (publicKey) => {
      setConnected(true);
      setPublicKey(publicKey.toString());
    };

    const handleDisconnect = () => {
      setConnected(false);
      setPublicKey(null);
    };

    window.solana?.on('connect', handleConnect);
    window.solana?.on('disconnect', handleDisconnect);
    window.solflare?.on('connect', handleConnect);
    window.solflare?.on('disconnect', handleDisconnect);

    return () => {
      window.solana?.off('connect', handleConnect);
      window.solana?.off('disconnect', handleDisconnect);
      window.solflare?.off('connect', handleConnect);
      window.solflare?.off('disconnect', handleDisconnect);
    };
  }, []);

  const connectPhantom = async () => {
    if (isMobile) {
      const dappUrl = window.location.href;
      const phantomUrl = `https://phantom.app/ul/browse/${encodeURIComponent(dappUrl)}`;
      window.location.href = phantomUrl;
      return;
    }
    // ... existing Phantom desktop logic ...
  };

  const connectSolflare = async () => {
    if (isMobile) {
      const dappUrl = window.location.href;
      // Create a unique session ID
      const sessionId = Math.random().toString(36).substring(2);
      // Add connection parameters
      const params = new URLSearchParams({
        dapp_url: dappUrl,
        dapp_name: 'SolanaApp',
        session: sessionId,
        redirect_link: dappUrl
      }).toString();
      
      // Use the correct deep link format for Solflare
      const solflareUrl = `https://solflare.com/ul/v1/connect?${params}`;
      window.location.href = solflareUrl;
      return;
    }
    
    // Desktop logic
    if (window.solflare) {
      try {
        const response = await window.solflare.connect();
        setWallet(window.solflare);
        setWalletType('solflare');
        setConnected(true);
        setPublicKey(response.publicKey.toString());
      } catch (error) {
        console.error('Solflare connection error:', error);
        window.open('https://solflare.com', '_blank');
      }
    } else {
      window.open('https://solflare.com', '_blank');
    }
  };

  const connect = async (type = 'phantom') => {
    try {
      if (type === 'phantom') {
        await connectPhantom();
      } else if (type === 'solflare') {
        await connectSolflare();
      }
    } catch (error) {
      console.error('Connection error:', error);
    }
  };

  // Handle returning from mobile wallet
  useEffect(() => {
    const handleMobileWalletReturn = async () => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      
      try {
        if (params.has('phantom_encryption_public_key')) {
          // Handle Phantom return
          const selectedWallet = window.solana;
          if (selectedWallet) {
            const response = await selectedWallet.connect();
            setWallet(selectedWallet);
            setWalletType('phantom');
            setConnected(true);
            setPublicKey(response.publicKey.toString());
          }
        } else if (params.has('session') || params.has('solflare_response')) {
          // Handle Solflare return
          const selectedWallet = window.solflare;
          if (selectedWallet) {
            const response = await selectedWallet.connect();
            setWallet(selectedWallet);
            setWalletType('solflare');
            setConnected(true);
            setPublicKey(response.publicKey.toString());
          }
        }
      } catch (error) {
        console.error('Mobile wallet return error:', error);
      }
    };

    if (isMobile) {
      handleMobileWalletReturn();
    }
  }, [isMobile]);

  const disconnect = async () => {
    try {
      if (wallet) {
        await wallet.disconnect();
        setWallet(null);
        setWalletType(null);
        setConnected(false);
        setPublicKey(null);
        sessionStorage.removeItem('walletAddress');
      }
    } catch (error) {
      console.error('Disconnect error:', error);
    }
  };

  const value = {
    wallet,
    walletType,
    connected,
    publicKey,
    walletAddress: publicKey,
    connect,
    disconnect
  };

  return (
    <WalletContext.Provider value={value}>
      {children}
    </WalletContext.Provider>
  );
}; 