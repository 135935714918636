const STORAGE_KEY = 'chat_history';
const MAX_MESSAGES = 500;  // Increased message limit

export const loadChatHistory = () => {
  try {
    const history = localStorage.getItem(STORAGE_KEY);
    const parsedHistory = history ? JSON.parse(history) : [];
    // Only keep messages from the last 24 hours
    const oneDayAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return parsedHistory.filter(msg => new Date(msg.timestamp) > oneDayAgo);
  } catch (error) {
    console.error('Error loading chat history:', error);
    return [];
  }
};

export const saveMessage = (message) => {
  try {
    const history = loadChatHistory();
    const updatedHistory = [...history, message];
    // Keep only the last MAX_MESSAGES
    if (updatedHistory.length > MAX_MESSAGES) {
      updatedHistory.splice(0, updatedHistory.length - MAX_MESSAGES);
    }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedHistory));
  } catch (error) {
    console.error('Error saving message:', error);
  }
}; 