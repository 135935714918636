import React, { useState, useRef } from 'react';
import MenuBar from '../components/MenuBar';
import Taskbar from '../components/Taskbar';
import WindowManager from '../components/WindowManager';
import LoginScreen from './LoginScreen';
import './DesktopScreen.css';

const DesktopScreen = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const windowManagerRef = useRef(null);
  const [openWindows, setOpenWindows] = useState([]);

  const handleLogout = () => {
    localStorage.removeItem('user');
    setIsLoggedIn(false);
  };

  const handleAppClick = (appId) => {
    windowManagerRef.current?.openApp(appId);
  };

  if (!isLoggedIn) {
    return <LoginScreen />;
  }

  return (
    <div className="desktop-screen">
      <MenuBar onLogout={handleLogout} />
      <WindowManager 
        ref={windowManagerRef}
        onWindowsChange={setOpenWindows} 
      />
      <Taskbar 
        onAppClick={handleAppClick}
        openWindows={openWindows}
      />
    </div>
  );
};

export default DesktopScreen;
