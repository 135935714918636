import React, { useState, forwardRef, useRef, useCallback } from 'react';
import { loadApp } from '../apps/config';
import './WindowManager.css';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const Window = forwardRef(({ window: windowData, isMaximized, isActive, isMinimized, onClose, onMinimize, onMaximize, onBringToFront, onUpdatePosition }, ref) => {
  const windowRef = useRef(null);

  const handleTitleMouseDown = (e) => {
    if (isMaximized || e.target.closest('.window-controls')) return;
    e.preventDefault();

    const isTouchEvent = e.type.startsWith('touch');
    const pageX = isTouchEvent ? e.touches[0].pageX : e.pageX;
    const pageY = isTouchEvent ? e.touches[0].pageY : e.pageY;

    let startX = pageX - windowData.position.x;
    let startY = pageY - windowData.position.y;

    function handleMove(e) {
      const currentX = e.type.startsWith('touch') ? e.touches[0].pageX : e.pageX;
      const currentY = e.type.startsWith('touch') ? e.touches[0].pageY : e.pageY;

      let newX = currentX - startX;
      let newY = currentY - startY;

      if (isMobile) {
        const maxX = window.innerWidth - windowRef.current.offsetWidth;
        const maxY = window.innerHeight - windowRef.current.offsetHeight;
        newX = Math.max(0, Math.min(newX, maxX));
        newY = Math.max(32, Math.min(newY, maxY));
      }

      onUpdatePosition({
        x: newX,
        y: newY,
        width: windowData.position.width,
        height: windowData.position.height
      });
    }

    function handleEnd() {
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    }

    document.addEventListener('mousemove', handleMove);
    document.addEventListener('mouseup', handleEnd);
    document.addEventListener('touchmove', handleMove, { passive: false });
    document.addEventListener('touchend', handleEnd);
  };

  const handleResizeStart = (e, type) => {
    if (isMaximized) return;
    e.preventDefault();
    e.stopPropagation();

    const startPos = {
      x: e.clientX,
      y: e.clientY,
      width: windowData.position.width,
      height: windowData.position.height
    };

    function mouseMoveHandler(e) {
      const deltaX = e.clientX - startPos.x;
      const deltaY = e.clientY - startPos.y;
      
      let newWidth = startPos.width;
      let newHeight = startPos.height;
      
      const minWidth = windowData.appId === 'calculator' ? 230 : 200;
      const minHeight = windowData.appId === 'calculator' ? 500 : 200;
      
      switch (type) {
        case 'e':
          newWidth = Math.max(minWidth, startPos.width + deltaX);
          break;
        case 's':
          newHeight = Math.max(minHeight, startPos.height + deltaY);
          break;
        case 'se':
          newWidth = Math.max(minWidth, startPos.width + deltaX);
          newHeight = Math.max(minHeight, startPos.height + deltaY);
          break;
        default:
          break;
      }
      
      onUpdatePosition({
        x: windowData.position.x,
        y: windowData.position.y,
        width: newWidth,
        height: newHeight
      });
    }

    function mouseUpHandler() {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    }

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  return (
    <div 
      ref={windowRef}
      className={`window ${isActive ? 'active' : ''} ${windowData.appId} ${isMaximized ? 'maximized' : ''}`}
      style={{ 
        zIndex: windowData.zIndex,
        display: isMinimized ? 'none' : 'block',
        left: `${windowData.position.x}px`,
        top: `${windowData.position.y}px`,
        width: `${windowData.position.width}px`,
        height: `${windowData.position.height}px`
      }}
      onClick={onBringToFront}
    >
      <div 
        className="window-titlebar"
        onMouseDown={handleTitleMouseDown}
        onTouchStart={handleTitleMouseDown}
      >
        <div className="window-controls">
          <button className="close-button" onClick={onClose} />
          <button className="minimize-button" onClick={onMinimize} />
          <button className="maximize-button" onClick={onMaximize} />
        </div>
        <div className="window-title">
          {windowData.title || 'Untitled'}
        </div>
      </div>
      <div className="window-content">
        <windowData.component />
      </div>
      {!isMaximized && windowData.appId !== 'calculator' && (
        <>
          <div 
            className="resize-handle resize-handle-e" 
            onMouseDown={(e) => handleResizeStart(e, 'e')}
          />
          <div 
            className="resize-handle resize-handle-s" 
            onMouseDown={(e) => handleResizeStart(e, 's')}
          />
          <div 
            className="resize-handle resize-handle-se" 
            onMouseDown={(e) => handleResizeStart(e, 'se')}
          />
        </>
      )}
    </div>
  );
});

const WindowManager = forwardRef((props, ref) => {
  const [openWindows, setOpenWindows] = useState([]);
  const [activeWindow, setActiveWindow] = useState(null);
  const [minimizedWindows, setMinimizedWindows] = useState(new Set());
  const [maximizedWindows, setMaximizedWindows] = useState(new Set());
  const windowRefs = useRef({});

  const bringToFront = useCallback((windowId) => {
    setActiveWindow(windowId);
    setOpenWindows(prev => {
      const maxZ = Math.max(...prev.map(w => w.zIndex));
      return prev.map(w => ({
        ...w,
        zIndex: w.id === windowId ? maxZ + 1 : w.zIndex
      }));
    });
  }, []);

  const handleOpenApp = useCallback(async (appId) => {
    const isAppOpen = openWindows.some(window => window.appId === appId);
    if (isAppOpen) {
      const existingWindow = openWindows.find(window => window.appId === appId);
      if (minimizedWindows.has(existingWindow.id)) {
        setMinimizedWindows(prev => {
          const next = new Set(prev);
          next.delete(existingWindow.id);
          return next;
        });
      }
      bringToFront(existingWindow.id);
      return;
    }

    try {
      const AppComponent = await loadApp(appId);
      const offset = isMobile ? 0 : openWindows.length * 30;
      let initialPosition;

      if (isMobile) {
        const width = appId === 'calculator' ? 280 : window.innerWidth * 0.95;
        const height = appId === 'calculator' ? 400 : window.innerHeight - 80;
        initialPosition = {
          x: appId === 'calculator' ? (window.innerWidth - 280) / 2 : window.innerWidth * 0.025,
          y: 40,
          width,
          height
        };
      } else {
        const defaultSizes = {
          calculator: { width: 230, height: 500 },
          music: { width: 800, height: 600 },
          terminal: { width: 600, height: 400 },
          safari: { width: 1000, height: 700 }
        };

        const defaultSize = defaultSizes[appId] || { width: 700, height: 500 };

        initialPosition = {
          x: 100 + offset,
          y: 50 + offset,
          width: defaultSize.width,
          height: defaultSize.height
        };
      }

      const newWindow = {
        id: Date.now(),
        appId,
        title: appId.charAt(0).toUpperCase() + appId.slice(1),
        component: AppComponent,
        zIndex: openWindows.length + 1,
        position: initialPosition
      };

      setOpenWindows(prev => [...prev, newWindow]);
      setActiveWindow(newWindow.id);
    } catch (error) {
      console.error('Failed to open app:', error);
    }
  }, [openWindows, minimizedWindows, bringToFront]);

  React.useImperativeHandle(ref, () => ({
    openApp: handleOpenApp
  }), [handleOpenApp]);

  return (
    <div className="window-manager">
      {openWindows.map(window => (
        <Window
          key={window.id}
          ref={windowRefs.current[window.id]}
          window={window}
          isMaximized={maximizedWindows.has(window.id)}
          isActive={activeWindow === window.id}
          isMinimized={minimizedWindows.has(window.id)}
          onClose={() => {
            setOpenWindows(prev => prev.filter(w => w.id !== window.id));
            setMaximizedWindows(prev => {
              const next = new Set(prev);
              next.delete(window.id);
              return next;
            });
          }}
          onMinimize={() => {
            setMinimizedWindows(prev => {
              const next = new Set(prev);
              next.add(window.id);
              return next;
            });
          }}
          onMaximize={() => {
            setMaximizedWindows(prev => {
              const next = new Set(prev);
              if (next.has(window.id)) {
                next.delete(window.id);
              } else {
                next.add(window.id);
              }
              return next;
            });
          }}
          onBringToFront={() => bringToFront(window.id)}
          onUpdatePosition={(position) => {
            setOpenWindows(prev => prev.map(w => 
              w.id === window.id ? {
                ...w,
                position: {
                  ...w.position,
                  x: position.x,
                  y: position.y,
                  width: position.width,
                  height: position.height
                }
              } : w
            ));
          }}
        />
      ))}
    </div>
  );
});

export default WindowManager;