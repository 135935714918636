import React, { useState } from 'react';
import './Safari.css';

const Safari = () => {
  const [url, setUrl] = useState('');
  const [displayUrl, setDisplayUrl] = useState('');
  const [history, setHistory] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [error, setError] = useState(null);

  const handleNavigate = (input) => {
    try {
      let processedUrl = input.trim();
      
      // Check if it's a search query or URL
      if (!processedUrl.includes('.') || processedUrl.includes(' ')) {
        // Use DuckDuckGo instead of Google
        processedUrl = `https://duckduckgo.com/?q=${encodeURIComponent(processedUrl)}`;
      } else if (!processedUrl.startsWith('http')) {
        processedUrl = `https://${processedUrl}`;
      }
      
      // Validate URL
      new URL(processedUrl);
      
      setUrl(processedUrl);
      setDisplayUrl(processedUrl);
      const newHistory = [...history.slice(0, currentIndex + 1), processedUrl];
      setHistory(newHistory);
      setCurrentIndex(newHistory.length - 1);
      setError(null);
      
    } catch (error) {
      setError('Invalid URL');
    }
  };

  return (
    <div className="safari-browser">
      <div className="browser-toolbar">
        <div className="navigation-buttons">
          <button 
            onClick={() => currentIndex > 0 && handleNavigate(history[currentIndex - 1])}
            disabled={currentIndex <= 0}
          >
            ←
          </button>
          <button 
            onClick={() => currentIndex < history.length - 1 && handleNavigate(history[currentIndex + 1])}
            disabled={currentIndex >= history.length - 1}
          >
            →
          </button>
          <button onClick={() => handleNavigate(displayUrl)} className="refresh-button">
            ↻
          </button>
        </div>
        <div className="url-bar">
          <input
            type="text"
            value={displayUrl}
            onChange={(e) => setDisplayUrl(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleNavigate(e.target.value);
              }
            }}
            onFocus={(e) => e.target.select()}
            placeholder="Search Google or enter a URL"
          />
        </div>
      </div>
      <div className="browser-content">
        {url ? (
          <iframe
            src={url}
            title="Safari Browser"
            sandbox="allow-scripts allow-same-origin allow-popups allow-forms allow-downloads allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-top-navigation"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="no-referrer"
            loading="lazy"
            onError={() => setError('Failed to load page')}
          />
        ) : (
          <div className="browser-home">
            <h2>Welcome to Safari</h2>
            <p>Search Google or enter a URL to begin browsing</p>
          </div>
        )}
        {error && (
          <div className="browser-error">
            <h3>Error Loading Page</h3>
            <p>{error}</p>
            <p>Try visiting another website or check your connection.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Safari; 