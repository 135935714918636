import React, { useState, useEffect, useRef } from 'react';
import { useWallet } from '../../../context/WalletConnectionProvider';
import { generateGuestName } from './utils/nameGenerator';
import { loadChatHistory, saveMessage } from './utils/chatStorage';
import ChatWebSocket from './utils/websocket';
import ChatMessageList from './components/ChatMessageList';
import ChatInput from './components/ChatInput';
import UsersList from './components/UsersList';
import './Chat.css';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [currentDM, setCurrentDM] = useState(null);
  const [username, setUsername] = useState('');
  const { walletAddress, walletType } = useWallet();
  const messagesEndRef = useRef(null);
  const wsRef = useRef(null);

  useEffect(() => {
    // Check for existing username in sessionStorage
    const savedUsername = sessionStorage.getItem('chatUsername');
    
    if (savedUsername) {
      setUsername(savedUsername);
    } else {
      // Set username based on wallet or generate guest name
      if (walletAddress) {
        const shortAddress = `${walletAddress.slice(0, 4)}...${walletAddress.slice(-4)}`;
        const newUsername = `${walletType}_${shortAddress}`;
        setUsername(newUsername);
        sessionStorage.setItem('chatUsername', newUsername);
      } else {
        const guestName = generateGuestName();
        setUsername(guestName);
        sessionStorage.setItem('chatUsername', guestName);
      }
    }

    // Initialize WebSocket
    wsRef.current = new ChatWebSocket((message) => {
      if (message.type === 'chat') {
        if (!blockedUsers.includes(message.userId)) {
          setMessages(prev => [...prev, message]);
        }
      } else if (message.type === 'history') {
        // Load initial chat history
        setMessages(message.messages);
      } else if (message.type === 'users_update') {
        setUsers(message.users);
      } else if (message.type === 'dm') {
        if (!blockedUsers.includes(message.userId)) {
          if (currentDM?.id === message.userId) {
            setMessages(prev => [...prev, message]);
          }
        }
      }
    });

    return () => {
      if (wsRef.current) {
        wsRef.current.disconnect();
      }
    };
  }, [walletAddress, walletType, blockedUsers, currentDM]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = (content) => {
    const newMessage = {
      id: Date.now(),
      content,
      username,
      userId: walletAddress || username,
      timestamp: new Date().toISOString(),
      isWalletUser: !!walletAddress,
      isDM: !!currentDM
    };

    // Send message through WebSocket
    if (wsRef.current) {
      wsRef.current.sendMessage({
        ...newMessage,
        recipientId: currentDM?.id
      });
    }

    setMessages(prev => [...prev, newMessage]);
    saveMessage(newMessage);
  };

  const handleToggleBlock = (userId) => {
    setBlockedUsers(prev => 
      prev.includes(userId)
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };

  const handleStartDM = (user) => {
    if (user.id === (walletAddress || username)) return;
    
    setCurrentDM(user);
    // Request DM history from server
    if (wsRef.current) {
      wsRef.current.sendMessage({
        type: 'get_dm_history',
        userId1: walletAddress || username,
        userId2: user.id
      });
    }
  };

  const handleExitDM = () => {
    setCurrentDM(null);
    const history = loadChatHistory().filter(msg => !msg.isDM);
    setMessages(history);
  };

  const handleReaction = (messageId, reaction) => {
    // Send reaction through WebSocket
    if (wsRef.current) {
      wsRef.current.sendMessage({
        type: 'reaction',
        messageId,
        reaction,
        username
      });
    }
  };

  return (
    <div className="chat-app">
      <UsersList
        users={users}
        currentUser={walletAddress || username}
        blockedUsers={blockedUsers}
        onStartDM={handleStartDM}
        onToggleBlock={handleToggleBlock}
        selectedUser={currentDM}
        connectedAs={username}
      />
      <div className="chat-main">
        <div className="chat-header">
          <h2>
            {currentDM ? (
              <div className="dm-header">
                <span>Chat with {currentDM.username}</span>
                <button className="exit-dm" onClick={handleExitDM}>×</button>
              </div>
            ) : (
              'General Chat'
            )}
          </h2>
        </div>
        
        <ChatMessageList 
          messages={messages}
          currentUser={username}
          onReaction={handleReaction}
        />
        
        <ChatInput onSendMessage={handleSendMessage} />
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default Chat; 