import Calculator from './store/Calculator/Calculator';
import Terminal from './store/Terminal/Terminal';
import MusicPlayer from './store/MusicPlayer/MusicPlayer';
import Safari from './store/Safari/Safari';
import Chat from './store/Chat/Chat';
import calculatorIcon from '../assets/calculator.png';
import terminalIcon from '../assets/terminal.png';
import musicIcon from '../assets/Apple_Music.png';
import safariIcon from '../assets/safari.png';
import chatIcon from '../assets/chat.png';

const apps = {
  calculator: {
    id: 'calculator',
    title: 'Calculator',
    component: Calculator,
    icon: calculatorIcon
  },
  terminal: {
    id: 'terminal',
    title: 'Terminal',
    component: Terminal,
    icon: terminalIcon
  },
  music: {
    id: 'music',
    title: 'Music',
    component: MusicPlayer,
    icon: musicIcon
  },
  safari: {
    id: 'safari',
    title: 'Safari',
    component: Safari,
    icon: safariIcon
  },
  chat: {
    id: 'chat',
    title: 'Chat',
    component: Chat,
    icon: chatIcon
  }
};

export const loadApp = async (appId) => {
  const app = apps[appId];
  if (!app) {
    throw new Error(`App ${appId} not found`);
  }
  return app.component;
};

export const getAppIcon = (appId) => {
  return apps[appId]?.icon || '❓';
};

export const getAppTitle = (appId) => {
  return apps[appId]?.title || 'Unknown App';
};

export const getInstalledApps = () => {
  return Object.values(apps);
};

export default apps; 