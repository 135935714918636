import React from 'react';

const ChatMessageList = ({ messages, currentUser }) => {
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="chat-messages">
      {messages.map(message => (
        <div 
          key={message.id}
          className={`message ${message.username === currentUser ? 'own-message' : ''}`}
        >
          <div className="message-header">
            <span className="username">{message.username}</span>
            <span className="timestamp">{formatTime(message.timestamp)}</span>
          </div>
          <div className="message-content">{message.content}</div>
        </div>
      ))}
    </div>
  );
};

export default ChatMessageList; 