import React, { useState, useRef, useEffect } from 'react';

const EMOJI_LIST = [
  '😊', '😂', '🥰', '😎', '🤔', '👍', '❤️', '🎉',
  '🔥', '✨', '🌟', '💯', '🙌', '👏', '🤝', '💪',
  '🚀', '💡', '💭', '💬', '📝', '🎵', '🎮', '🌈'
];

const ChatInput = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      onSendMessage(message.trim());
      setMessage('');
    }
  };

  const handleEmojiClick = (emoji) => {
    setMessage(prev => prev + emoji);
    setShowEmojiPicker(false);
  };

  return (
    <div className="chat-input-container">
      <form className="chat-input" onSubmit={handleSubmit} ref={emojiPickerRef}>
        <button 
          type="button" 
          className="emoji-button"
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        >
          😊
        </button>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
          maxLength={500}
        />
        <button type="submit" disabled={!message.trim()}>
          Send
        </button>
        {showEmojiPicker && (
          <div className="emoji-picker">
            {EMOJI_LIST.map((emoji, index) => (
              <button
                key={index}
                className="emoji-item"
                onClick={() => handleEmojiClick(emoji)}
              >
                {emoji}
              </button>
            ))}
          </div>
        )}
      </form>
    </div>
  );
};

export default ChatInput; 