import React, { createContext, useState, useContext } from 'react';
import defaultAlbumArt from '../../../assets/music/defaultalbum.png';

// Import all music files from the assets/music directory
const musicFiles = {};
const imageFiles = {};

// Use require.context to get all mp3 and png files
function importAll(r) {
  r.keys().forEach(key => {
    const fileName = key.replace('./', '');
    if (fileName.endsWith('.mp3')) {
      musicFiles[fileName] = r(key);
    } else if (fileName.endsWith('.png') && fileName !== 'defaultalbum.png') {
      imageFiles[fileName] = r(key);
    }
  });
}

// Import all files from the music directory
importAll(require.context('../../../assets/music', false, /\.(mp3|png)$/));

const MusicContext = createContext();

export const MusicProvider = ({ children }) => {
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(75);

  const value = {
    currentTrack,
    setCurrentTrack,
    isPlaying,
    setIsPlaying,
    volume,
    setVolume
  };

  return (
    <MusicContext.Provider value={value}>
      {children}
    </MusicContext.Provider>
  );
};

export const useMusic = () => {
  const context = useContext(MusicContext);
  if (!context) {
    throw new Error('useMusic must be used within a MusicProvider');
  }
  return context;
};

export const loadMusicFiles = async () => {
  try {
    const tracks = [];
    
    // Process each music file
    Object.keys(musicFiles).forEach(fileName => {
      const baseName = fileName.replace('.mp3', '');
      const pngFileName = `${baseName}.png`;
      
      tracks.push({
        title: baseName.replace(/([A-Z])/g, ' $1').trim(),
        artist: 'Unknown Artist',
        album: 'Auto Loaded',
        year: '2024',
        duration: 0,
        url: musicFiles[fileName],
        imageUrl: imageFiles[pngFileName] || defaultAlbumArt
      });
    });
    
    console.log('Loaded tracks:', tracks);
    return tracks;
    
  } catch (error) {
    console.error('Error loading music files:', error);
    return [];
  }
}; 