import React, { useState } from 'react';
import './Taskbar.css';
import { getInstalledApps } from '../apps/config';

const Taskbar = ({ onAppClick, openWindows = [] }) => {
  const [isHovered, setIsHovered] = useState(false);
  const installedApps = getInstalledApps();
  const activeAppIds = openWindows.map(window => window.appId);
  const hasActiveApps = activeAppIds.length > 0;

  return (
    <div 
      className={`taskbar ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={`taskbar-content ${hasActiveApps ? 'has-active-apps' : ''}`}>
        {installedApps.map((app) => (
          <div 
            key={app.id}
            className={`taskbar-icon ${activeAppIds.includes(app.id) ? 'active' : ''}`}
            onClick={() => onAppClick(app.id)}
          >
            <img src={app.icon} alt={app.name} />
            {activeAppIds.includes(app.id) && <div className="app-indicator" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Taskbar;
