import React, { useState, useEffect, forwardRef } from 'react';
import './MenuBar.css';
import { useMusic } from '../context/MusicContext';
import { useWallet } from '../context/WalletConnectionProvider';
import appleLogo from '../assets/applelogo.png';
import wifiIcon from '../assets/wifi.png';
import bluetoothIcon from '../assets/bluetooth.png';
import batteryIcon from '../assets/battery.100.png';
import controlCenter from '../assets/controlcenter.png';
import airdropIcon from '../assets/airdrop.png';
import speakerIcon from '../assets/speaker.3.fill.png';
import phantomIcon from '../assets/phantom.png';
import solflareIcon from '../assets/solflare.png';
import profileImg from '../assets/profileimg.png';

const MenuBar = forwardRef(({ onLogout }, ref) => {
  const [showControlCenter, setShowControlCenter] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isWifiActive, setIsWifiActive] = useState(true);
  const [isBluetoothActive, setIsBluetoothActive] = useState(true);
  const [isAirdropActive, setIsAirdropActive] = useState(false);
  const [showAppleMenu, setShowAppleMenu] = useState(false);
  const [brightness, setBrightness] = useState(100);
  
  const { currentTrack, isPlaying, volume, setVolume } = useMusic();
  const { walletAddress, walletType, disconnect } = useWallet();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = () => {
    return currentTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  const handleBrightnessChange = (e) => {
    const value = parseInt(e.target.value);
    setBrightness(value);
    document.documentElement.style.filter = `brightness(${value}%)`;
  };

  const handleOpenMusicPlayer = () => {
    if (ref?.current) {
      ref.current.openApp('music');
      setShowControlCenter(false);
    }
  };

  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const getWalletIcon = () => {
    switch (walletType) {
      case 'phantom':
        return phantomIcon;
      case 'solflare':
        return solflareIcon;
      default:
        return null;
    }
  };

  const handleLogout = () => {
    if (walletAddress) {
      disconnect();
    }
    onLogout();
  };

  return (
    <>
      <div className="menubar">
        <div className="left-menu">
          <div className="apple-logo-wrapper" onClick={() => setShowAppleMenu(!showAppleMenu)}>
            <img src={appleLogo} alt="Apple" className="apple-logo" />
          </div>
          <div className="user-info">
            {walletAddress && walletType ? (
              <div className="wallet-info">
                <img 
                  src={getWalletIcon()} 
                  alt={`${walletType} wallet`} 
                  className="wallet-icon" 
                />
                <span className="wallet-address">
                  {formatWalletAddress(walletAddress)}
                </span>
              </div>
            ) : (
              <div className="guest-info">
                <img src={profileImg} alt="Guest" className="user-icon" />
                <span className="user-name">Guest</span>
              </div>
            )}
          </div>
          {showAppleMenu && (
            <div className="apple-menu">
              <div className="menu-item" onClick={handleLogout}>Logout</div>
            </div>
          )}
        </div>
        <div className="right-menu">
          {isWifiActive && (
            <div className="menu-icon-wrapper">
              <img src={wifiIcon} alt="WiFi" className="menu-icon" />
            </div>
          )}
          {isBluetoothActive && (
            <div className="menu-icon-wrapper">
              <img src={bluetoothIcon} alt="Bluetooth" className="menu-icon" />
            </div>
          )}
          <div className="menu-icon-wrapper">
            <img src={batteryIcon} alt="Battery" className="menu-icon" />
          </div>
          <div className="menu-icon-wrapper">
            <img src={controlCenter} alt="Control Center" className="menu-icon" onClick={() => setShowControlCenter(!showControlCenter)} />
          </div>
          <div className="time">{formatTime()}</div>
        </div>
      </div>

      {showControlCenter && (
        <div className="control-center">
          <div className="control-center-grid">
            <div className="control-module" onClick={() => setIsWifiActive(!isWifiActive)}>
              <div className={`module-icon ${isWifiActive ? 'active' : 'inactive'}`}>
                <img src={wifiIcon} alt="WiFi" />
              </div>
              <div className="module-text">
                <div className="module-title">Wi-Fi</div>
                <div className="module-subtitle">{isWifiActive ? 'On' : 'Off'}</div>
              </div>
            </div>

            <div className="control-module" onClick={() => setIsBluetoothActive(!isBluetoothActive)}>
              <div className={`module-icon ${isBluetoothActive ? 'active' : 'inactive'}`}>
                <img src={bluetoothIcon} alt="Bluetooth" />
              </div>
              <div className="module-text">
                <div className="module-title">Bluetooth</div>
                <div className="module-subtitle">{isBluetoothActive ? 'On' : 'Off'}</div>
              </div>
            </div>

            <div className="control-module" onClick={() => setIsAirdropActive(!isAirdropActive)}>
              <div className={`module-icon ${isAirdropActive ? 'active' : 'inactive'}`}>
                <img src={airdropIcon} alt="AirDrop" />
              </div>
              <div className="module-text">
                <div className="module-title">AirDrop</div>
                <div className="module-subtitle">{isAirdropActive ? 'Contacts Only' : 'Off'}</div>
              </div>
            </div>
          </div>

          <div className="control-slider">
            <div className="slider-label">
              <img src={controlCenter} alt="Brightness" className="slider-icon" />
              Display
            </div>
            <input
              type="range"
              min="20"
              max="100"
              value={brightness}
              onChange={handleBrightnessChange}
            />
          </div>

          <div className="control-slider">
            <div className="slider-label">
              <img src={speakerIcon} alt="Volume" className="slider-icon" />
              Sound
            </div>
            <input
              type="range"
              min="0"
              max="100"
              value={volume}
              onChange={(e) => setVolume(parseInt(e.target.value))}
            />
          </div>

          <div 
            className="music-player"
            onClick={handleOpenMusicPlayer}
            style={{ cursor: 'pointer' }}
          >
            {isPlaying && currentTrack ? (
              <>
                <img 
                  src={currentTrack.imageUrl} 
                  alt="Album Art" 
                  className="album-art" 
                />
                <div className="music-info">
                  <div className="music-title">{currentTrack.title}</div>
                  <div className="music-artist">
                    {currentTrack.artist} • {currentTrack.album} • {currentTrack.year}
                  </div>
                </div>
              </>
            ) : (
              <div className="music-info">
                <div className="music-title">No music playing</div>
                <div className="music-artist">Open Music Player</div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
});

export default MenuBar;