import React, { useRef, useEffect, useState } from 'react';
import './MusicPlayer.css';
import { loadMusicFiles } from './loadMusic';
import { useMusic } from '../../../context/MusicContext';
import defaultAlbumArt from '../../../assets/music/defaultalbum.png';
import { FaPlay, FaPause, FaStepForward, FaStepBackward, FaVolumeUp } from 'react-icons/fa';

const MusicPlayer = () => {
  const [playlist, setPlaylist] = useState([]);
  const audioRef = useRef(null);
  const { currentTrack, setCurrentTrack, isPlaying, setIsPlaying, volume, setVolume } = useMusic();
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [trackDurations, setTrackDurations] = useState({});

  useEffect(() => {
    const loadPlaylist = async () => {
      try {
        const tracks = await loadMusicFiles();
        setPlaylist(tracks);
        tracks.forEach(loadTrackDuration);
      } catch (error) {
        console.error('Error in loadPlaylist:', error);
        setPlaylist([]);
      }
    };
    loadPlaylist();
  }, []);

  const handlePlayPause = (track) => {
    if (currentTrack && currentTrack.url === track.url) {
      setIsPlaying(!isPlaying);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setCurrentTrack(track);
      setTimeout(() => {
        setIsPlaying(true);
      }, 100);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume / 100;
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handlePlay = async () => {
      if (isPlaying && currentTrack) {
        try {
          audio.pause();
          await audio.load();
          await audio.play();
        } catch (error) {
          console.error('Error playing audio:', error);
          setIsPlaying(false);
        }
      } else {
        audio.pause();
      }
    };

    handlePlay();
  }, [isPlaying, currentTrack, setIsPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio && currentTrack) {
      const handleLoadedMetadata = () => {
        setDuration(audio.duration);
      };
      const handleTimeUpdate = () => {
        setCurrentTime(audio.currentTime);
      };
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.load();
      return () => {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [currentTrack]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume]);

  const playNextTrack = () => {
    if (playlist.length === 0) return;
    
    const currentIndex = playlist.findIndex(track => track.url === currentTrack?.url);
    const nextIndex = currentIndex === playlist.length - 1 ? 0 : currentIndex + 1;
    const nextTrack = playlist[nextIndex];
    
    if (audioRef.current) {
      audioRef.current.pause();
    }
    
    setCurrentTrack(nextTrack);
    setIsPlaying(false);
    
    requestAnimationFrame(() => {
      setIsPlaying(true);
    });
  };

  const playPreviousTrack = () => {
    if (playlist.length === 0) return;
    
    const currentIndex = playlist.findIndex(track => track.url === currentTrack?.url);
    const previousIndex = currentIndex === 0 ? playlist.length - 1 : currentIndex - 1;
    const previousTrack = playlist[previousIndex];
    
    if (audioRef.current) {
      audioRef.current.pause();
    }
    
    setCurrentTrack(previousTrack);
    setIsPlaying(false);
    
    requestAnimationFrame(() => {
      setIsPlaying(true);
    });
  };

  const loadTrackDuration = (track) => {
    const audio = new Audio(track.url);
    audio.addEventListener('loadedmetadata', () => {
      setTrackDurations(prev => ({
        ...prev,
        [track.url]: audio.duration
      }));
      audio.remove(); // Cleanup the audio element
    });
  };

  useEffect(() => {
    const audio = audioRef.current;
    
    return () => {
      if (audio) {
        audio.pause();
      }
    };
  }, []);

  return (
    <div className="music-player">
          
      <div className="main-content">
        <div className="now-playing">
          <img
            src={currentTrack?.imageUrl || defaultAlbumArt}
            alt="Album Art"
            className="album-art"
          />
          <div className="track-info">
            <div className="track-title">{currentTrack?.title || 'No track selected'}</div>
            <div className="track-details">
              {currentTrack ? `${currentTrack.artist} • ${currentTrack.album} • ${currentTrack.year}` : ''}
            </div>
          </div>
          
          <div className="controls">
            <div className="audio-controls">
              <span className="audio-time">{formatTime(currentTime)}</span>
              <input
                type="range"
                className="audio-progress"
                value={currentTime}
                max={duration}
                onChange={(e) => {
                  const newTime = parseFloat(e.target.value);
                  setCurrentTime(newTime);
                  audioRef.current.currentTime = newTime;
                }}
              />
              <span className="audio-time">{formatTime(duration)}</span>
            </div>
            
            <div className="control-buttons">
              <button 
                className="control-button"
                onClick={playPreviousTrack}
                disabled={playlist.length === 0}
              >
                <FaStepBackward />
              </button>
              <button 
                className="play-pause-button"
                onClick={() => currentTrack && handlePlayPause(currentTrack)}
              >
                {isPlaying ? <FaPause /> : <FaPlay />}
              </button>
              <button 
                className="control-button"
                onClick={playNextTrack}
                disabled={playlist.length === 0}
              >
                <FaStepForward />
              </button>
            </div>

            <div className="volume-control">
              <FaVolumeUp />
              <input
                type="range"
                min="0"
                max="100"
                value={volume}
                onChange={handleVolumeChange}
                className="volume-slider"
              />
            </div>
          </div>
        </div>

        <div className="playlist">
          {playlist.map((track, index) => (
            <div
              key={index}
              className={`track-item ${currentTrack?.url === track.url ? 'active' : ''}`}
              onClick={() => handlePlayPause(track)}
            >
              <span className="track-number">{index + 1}</span>
              <div className="track-info">
                <div className="track-title">{track.title}</div>
              </div>
              <span className="track-duration">
                {formatTime(trackDurations[track.url] || 0)}
              </span>
            </div>
          ))}
        </div>
      </div>

      <audio
        ref={audioRef}
        src={currentTrack?.url}
        onEnded={playNextTrack}
      />
    </div>
  );
};

export default MusicPlayer;