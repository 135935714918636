import React from 'react';
import './LoadingScreen.css';
import appleLogo from '../assets/applelogo.png';

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loading-content">
        <div className="logo-container">
          <img src={appleLogo} alt="Apple Logo" className="apple-logo-loading" />
          <div className="glow"></div>
        </div>
        <div className="loading-bar">
          <div className="loading-progress"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen; 