const adjectives = [
  'Happy', 'Lucky', 'Sunny', 'Clever', 'Swift', 
  'Bright', 'Cool', 'Super', 'Mega', 'Hyper'
];

const nouns = [
  'Panda', 'Tiger', 'Eagle', 'Dolphin', 'Lion',
  'Dragon', 'Phoenix', 'Wolf', 'Bear', 'Fox'
];

export const generateGuestName = () => {
  const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const noun = nouns[Math.floor(Math.random() * nouns.length)];
  const number = Math.floor(Math.random() * 1000);
  
  return `${adjective}${noun}${number}`;
}; 