import React, { useState, useEffect, useRef } from 'react';
import { LoginScreen, DesktopScreen } from './screens';
import LoadingScreen from './screens/LoadingScreen';
import './App.css';
import { MusicProvider } from './context/MusicContext';
import MenuBar from './components/MenuBar';
import WindowManager from './components/WindowManager';
import { WalletConnectionProvider } from './context/WalletConnectionProvider';

// Create a wrapper component to use the wallet context
const AppContent = () => {
  const [currentScreen, setCurrentScreen] = useState('loading');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const windowManagerRef = useRef(null);

  useEffect(() => {
    const bgImage = new Image();
    bgImage.src = require('./assets/macos-background.png');
    
    bgImage.onload = () => {
      setBackgroundLoaded(true);
      setTimeout(() => {
        const loadingScreen = document.querySelector('.loading-screen');
        if (loadingScreen) {
          loadingScreen.classList.add('fade-out');
        }
        
        setTimeout(() => {
          setCurrentScreen('login');
        }, 800);
      }, 3000);
    };
  }, []);

  const handleLogin = (type) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setShowLogin(false);
      setCurrentScreen('desktop');
      setIsTransitioning(false);
    }, 500);
  };

  const handleLogout = () => {
    setIsTransitioning(true);
    sessionStorage.removeItem('walletAddress');
    setTimeout(() => {
      setShowLogin(true);
      setCurrentScreen('login');
      setIsTransitioning(false);
    }, 500);
  };

  return (
    <div className={`App ${isTransitioning ? 'transitioning' : ''}`}>
      <div className="app-background" />
      
      {backgroundLoaded && showLogin && (
        <div className="screen-container">
          <LoginScreen onLogin={handleLogin} />
        </div>
      )}
      {currentScreen === 'loading' && (
        <div className="screen-container">
          <LoadingScreen />
        </div>
      )}
      {currentScreen === 'desktop' && (
        <>
          <div className="screen-container desktop-container">
            <DesktopScreen />
          </div>
          <MenuBar ref={windowManagerRef} onLogout={handleLogout} />
          <WindowManager ref={windowManagerRef} />
        </>
      )}
    </div>
  );
};

// Main App component with providers
function App() {
  return (
    <WalletConnectionProvider>
      <MusicProvider>
        <AppContent />
      </MusicProvider>
    </WalletConnectionProvider>
  );
}

export default App;
