class ChatWebSocket {
  constructor(onMessageReceived) {
    console.log('Initializing WebSocket');
    this.connect(onMessageReceived);
    this.onMessageReceived = onMessageReceived;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
  }

  connect(onMessageReceived) {
    const wsUrl = 'wss://solanapp.com:8080/ws';
    
    console.log('Connecting to WebSocket:', wsUrl);
    
    this.ws = new WebSocket(wsUrl);

    this.ws.onopen = () => {
      console.log('WebSocket connected, requesting history');
      this.reconnectAttempts = 0;
      this.sendMessage({ type: 'get_history' });
    };

    this.ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Received message type:', message.type);
      if (message.type === 'history') {
        console.log(`Received ${message.messages.length} messages from history`);
      }
      onMessageReceived(message);
    };

    this.ws.onclose = () => {
      console.log('WebSocket disconnected. Reconnecting...');
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        setTimeout(() => this.connect(this.onMessageReceived), 3000);
      }
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      console.log('Connection details:', {
        wsUrl,
        readyState: this.ws.readyState
      });
    };
  }

  sendMessage(message) {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket is not connected. Message not sent:', message);
    }
  }

  disconnect() {
    if (this.ws) {
      this.ws.close();
    }
  }
}

export default ChatWebSocket; 