import React, { useState, useEffect, useRef } from 'react';
import './Terminal.css';
import { useWallet } from '../../../context/WalletConnectionProvider';

const Terminal = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState(['Welcome to Terminal']);
  const inputRef = useRef(null);
  const { walletAddress, walletType } = useWallet();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleCommand = (cmd) => {
    const command = cmd.trim().toLowerCase();
    
    if (command === 'whoami') {
      if (walletAddress) {
        return [
          `Connected Wallet: ${walletAddress}`,
          `Type: ${walletType === 'phantom' ? 'Phantom' : 'Solflare'} Wallet`,
          `Status: Connected`
        ];
      } else {
        return ['guest@terminal'];
      }
    }

    if (command === 'clear') {
      setOutput([]);
      return [];
    }

    if (command === 'help') {
      return [
        'Available commands:',
        '  whoami    - Display current user info',
        '  clear     - Clear the terminal',
        '  help      - Show this help message'
      ];
    }

    if (command === '') {
      return [];
    }

    return [`Command not found: ${command}`];
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const newOutput = [...output, `$ ${input}`, ...handleCommand(input)];
      setOutput(newOutput);
      setInput('');
    }
  };

  return (
    <div className="terminal" onClick={() => inputRef.current?.focus()}>
      <div className="terminal-content">
        {output.map((line, i) => (
          <div key={i} className="terminal-line">
            {line}
          </div>
        ))}
        <div className="terminal-input">
          <span className="prompt">$ </span>
          <input
            ref={inputRef}
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        </div>
      </div>
    </div>
  );
};

export default Terminal; 