import React, { useEffect } from 'react';
import './LoginScreen.css';
import profileImg from '../assets/profileimg.png';
import phantomIcon from '../assets/phantom.png';
import solflareIcon from '../assets/solflare.png';
import { useWallet } from '../context/WalletConnectionProvider';

const LoginScreen = ({ onLogin }) => {
  const { connected, publicKey, connect } = useWallet();

  useEffect(() => {
    if (connected && publicKey) {
      sessionStorage.setItem('walletAddress', publicKey);
      onLogin('wallet');
    }
  }, [connected, publicKey, onLogin]);

  return (
    <div className="login-screen">
      <div className="login-options">
        <div className="login-option" onClick={() => onLogin('guest')}>
          <img src={profileImg} alt="Guest" className="user-avatar" />
          <span className="user-name">Guest User</span>
        </div>
        <div className="login-option" onClick={() => connect('phantom')}>
          <img src={phantomIcon} alt="Phantom" className="user-avatar" />
          <span className="user-name">Phantom Wallet</span>
        </div>
        <div className="login-option" onClick={() => connect('solflare')}>
          <img src={solflareIcon} alt="Solflare" className="user-avatar" />
          <span className="user-name">Solflare Wallet</span>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
